
import SEO from '~/mixins/SEO.js'
import BielbitBlogPosts from '~/mixins/BielbitBlogPosts.js'

export default {
  name: 'AktualnosciPage',
  mixins: [SEO, BielbitBlogPosts],
  data() {
    return {
      heroUnitImg: '/news.png',
      rawPageTitle: 'Aktualności',
      rawPageDescription:
        'Na tej stronie możesz się zapoznać z naszymi aktualnościami firmowymi.',
      keywords: [
        'insert',
        'firma',
        'czerwiec',
        'rozwiązanie',
        'program',
        'bielbit',
        'promocja',
        'pro',
        'oprogramowanie',
        'potrzeba',
        'gt',
        'nowy',
        'obrazek',
        'wpis',
      ],
    }
  },
  // head() {
  //   const head = {
  //     link: [],
  //   }
  //   head.link.push({
  //     rel: 'preload',
  //     as: 'fetch',
  //     href: `https://blog.bielbit.pl/wp-json/wp/v2/posts?page=1&per_page=4`,
  //   })
  //   return head
  // },
  computed: {
    bgImage() {
      return this.$device.isSamsung || this.$device.userAgent?.includes('OPR/')
        ? ''
        : this.$colorMode.preference === 'light'
          ? 'background-image: url(/trojkat-tlo-jasne.png);'
          : 'background-image: url(/trojkat-tlo-ciemne.png);'
    },
  },
}
